/*
	Add overrides to ion-components
	or default browser styles hers
*/

/**
	Button overrides
*/
button {
	text-transform: uppercase;
	font-weight: bold;
	border-radius: 2px;
	// --background: #0063a1;
	// --background-hover: #01385b;
	// --color: white;
	// --padding-top: 16px;
	// --padding-bottom: 16px;
	// font-size: 1.5rem;
	// width: 100%;
}

button.mat-button .mat-icon,
a.mat-button .mat-icon,
a.mat-raised-button .mat-icon,
a.mat-flat-button .mat-icon,
a.mat-stroked-button .mat-icon {
	vertical-align: top;
	font-size: 1.25em;
}

.CodeMirror {
	height: fit-content !important;
}

.mat-button,
.mat-flat-button,
.mat-icon-button,
.mat-stroked-button,
.mat-flat-button .mat-mini-fab {
	padding: 0 16px !important;
	border-radius: 0 !important;
	line-height: inherit !important;

	&[color='primary'] {
		background-color: var(--color-primary) !important;

		&:disabled {
			background-color: var(--color-primary-disabled) !important;
		}
		color: white !important;

		mat-icon {
			color: var(--white);
		}
	}

	&.no-padding {
		padding: 0 !important;
		min-width: auto !important;
	}

	&.size-small {
		padding: 0 10px !important;
		font-size: 0.7rem;
	}

	&.size-medium {
		padding-top: 6px !important;
		padding-bottom: 6px !important;
	}

	&.size-big {
		padding: 10px 26px !important;
	}

	&.mat-primary {
		background-color: var(--color-primary);
	}

	.mat-button-wrapper {
		display: flex;
		align-items: center;
		padding: 8px 5px;
		color: var(--white);
	}

	.mat-icon {
		font-size: 22px;
		line-height: 22px;
		height: 20px;
		width: 20px;
		margin-right: 8px;
	}

	&.no-focus-overlay {
		.mat-button-focus-overlay {
			display: none;
		}
	}

	&.color-primary {
		.mat-button-wrapper {
			color: var(--color-primary-contrast);
		}
	}

	&.round-border {
		border-radius: 4px !important;
	}

	&.with-icons {
		.mat-button-wrapper {
			display: flex;
			align-items: center;
			justify-content: center;
			gap: 4px;
		}
	}

	&.wrapper-tiny{
		.mat-button-wrapper{
			padding: 0;
		}
	}
}

.mat-button .mat-button-wrapper > *,
.mat-flat-button .mat-button-wrapper > *,
.mat-stroked-button .mat-button-wrapper > *,
.mat-raised-button .mat-button-wrapper > *,
.mat-icon-button .mat-button-wrapper > *,
.mat-fab .mat-button-wrapper > *,
.mat-mini-fab .mat-button-wrapper > * {
	vertical-align: initial !important;
}

button.mat-mini-fab {
	border-radius: var(--border-radius);

	&.button-extra-small {
		height: 36px;
		width: 36px;

		.mat-button-wrapper {
			padding: 6px;
		}

		mat-icon {
			height: 24px;
			width: 24px;
		}
	}
}

.mat-slider.mat-accent .mat-slider-track-fill, .mat-slider.mat-accent .mat-slider-thumb, .mat-slider.mat-accent .mat-slider-thumb-label {
	background-color: var(--color-primary) !important;
}

.mat-slider.mat-accent .mat-slider-thumb-label-text {
	color: white !important;
}

mat-form-field.no-hint {
	.mat-form-field-wrapper {
		margin-bottom: -1.25em;
	}
}

.item-has-focus {
	.label-floating {
		color: white !important;
	}
}

mat-form-field {
	&.large-text {
		.mat-form-field-wrapper {
			font-size: 1.1rem;
		}
	}

	&.no-label {
		.mat-form-field-flex,
		.mat-form-field-wrapper {
			padding-bottom: 0;
		}
		.mat-form-field-underline {
			bottom: 0;
		}
	}

	&.no-min-width {
		.mat-form-field-infix {
			min-width: 0;
			width: auto;
		}
	}

	&.no-bottom-padding {
		.mat-form-field-wrapper {
			padding-bottom: 0;
		}
	}
}

.mat-checkbox-indeterminate.mat-primary .mat-checkbox-background,
.mat-checkbox-checked.mat-primary .mat-checkbox-background,
.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background,
.mat-checkbox-checked.mat-accent .mat-checkbox-background {
	background-color: var(--color-primary);
	color: var(--text-color);

	.mat-checkbox-checkmark-path {
		stroke: white !important;
	}
}

.mat-checkbox-checked:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element,
.mat-checkbox:active:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element {
	background-color: var(--color-primary);
}

.mat-simple-snack-bar-content {
	color: var(--text-color);
}

mat-checkbox.dark {
	* {
		color: var(--text-color);
	}

	.mat-checkbox-mixedmark {
		background-color: white;
	}

	.mat-checkbox-inner-container {
		border: 1px solid var(--base-gray-40);
		border-radius: 4px;
	}

	.mat-checkbox-checkmark-path {
		stroke: white !important;
	}

	&.no-border {
		.mat-checkbox-inner-container {
			border: none;
		}
	}

	&.size-big {
		.mat-checkbox-inner-container {
			width: 24px;
			height: 24px;
		}
	}

	&.no-radius {
		.mat-checkbox-frame {
			border-radius: 0;
		}

		.mat-checkbox-background {
			border-radius: 0;
		}
	}

	&.no-disable.mat-checkbox-disabled {
		&.mat-checkbox-checked .mat-checkbox-background {
			background-color: var(--color-primary);
		}

		:not(.mat-checkbox-checked) .mat-checkbox-frame {
			border-color: inherit;
		}

		.mat-checkbox-label {
			color: inherit;
		}
	}
}

.mat-pseudo-checkbox {
	&::after {
		color: white;
	}
}

ion-alert {
	.alert-wrapper {
		background: #292929;
		border: 1px solid rgba(255, 255, 255, 0.1);
	}

	.ion-color-danger {
		background: var(--color-danger) !important;
		color: var(--text-color);
		font-weight: bold;
	}
}

snack-bar-container {
	&.success {
		background: var(--color-success);
		color: var(--text-color);
	}
	&.danger {
		background: var(--color-danger);
		color: var(--text-color);
	}
}

/* Change Autocomplete styles in Chrome*/
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
	border: none;
	-webkit-text-fill-color: white;
	-webkit-box-shadow: 0 0 0px 1000px #333 inset;
	transition: background-color 5000s ease-in-out 0s;
}

.mat-form-field .mat-input-element,
.mat-form-field-invalid .mat-input-element {
	caret-color: inherit;
}

.mat-form-field.mat-focused {
	.mat-form-field-ripple {
		background-color: var(--color-primary);
	}

	&.mat-primary .mat-select-arrow {
		color: var(--color-primary);
	}
}

[aria-checked='true'].sc-ion-alert-md .alert-radio-label.sc-ion-alert-md {
	color: var(--text-color);
}

.alert-radio-label.sc-ion-alert-md {
	color: #d3d3d3;
}

mat-form-field.dark {
	.mat-form-field-flex {
		background-color: rgb(240, 240, 240);
	}

	.mat-input-element,
	.mat-select-value *,
	.mat-select-arrow-wrapper .mat-select-arrow,
	.mat-form-field-invalid .mat-input-element {
		caret-color: black !important;
		color: var(--base-dark);
	}

	&.hide-label {
		.mat-form-field-flex {
			padding-top: 4px;
			padding-bottom: 10px;
		}

		.mat-select-arrow-wrapper {
			margin-top: 16px;
		}
	}

	input::placeholder {
		color: var(--color-gray);
		font-weight: 200;
	}
}

button.dark.mat-button-disabled {
	background-color: rgba(143, 143, 143, 0.12) !important;
	color: var(--base-dark) !important;
}

button.block {
	width: 100%;

	.mat-button-wrapper {
		justify-content: center;
	}
}

button.with-loading {
	.spinner {
		position: absolute;
		display: none;
	}

	&[disabled] {
		.text {
			opacity: 0.3;
		}
		.spinner {
			display: block;
			opacity: 1;
		}
	}
}

.cdk-overlay-pane {
	//width: unset !important;

	.mat-dialog-container {
		background: var(--base-color) !important;
		overflow: hidden;
	}

	&.mat-dialog-no-padding {
		.mat-dialog-container {
			padding: 0;
		}
	}

	&.fullscreen {
		.mat-dialog-container {
			width: 90vw !important;
			max-height: unset;
		}

		&.min-height {
			min-height: 84vh;

			.mat-dialog-container {
			}
		}

		&.stretch {
			.mat-dialog-container > * {
				height: 100%;
				display: flex;
				flex-direction: column;
			}

			.mat-dialog-content {
				flex: 1;
			}
		}

		&.stretch-height {
			height: 84vh;
		}

		&.limit-size {
			max-width: 84vw;
			max-height: 84vh;
		}
	}

	&.background-color {
		.mat-dialog-container {
			background: var(--background-color) !important;
		}
	}

	&.background-color-alternative {
		.mat-dialog-container {
			background: #1e1e1e !important;
		}
	}

	&.background-color-white {
		.mat-dialog-container {
			background: #ffffff !important;
		}
	}

	&.max-width-xlg {
		.mat-dialog-container {
			max-width: 1400px;
		}
	}

	&.max-width-lg {
		.mat-dialog-container {
			max-width: 1000px;
			max-height: 90vh;

			overflow: auto;
		}
	}

	&.max-width-md {
		.mat-dialog-container {
			max-width: 650px;
		}
	}

	&.max-width-sm {
		.mat-dialog-container {
			max-width: 480px;
		}
	}

	.mat-dialog-title,
	.mat-dialog-actions {
		display: flex;

		.right {
			margin-left: auto;
		}
	}
}

/* dark color for form elements */
@-moz-document url-prefix() {
	.mat-form-field-type-mat-input {
		.mat-form-field-label-wrapper {
			margin-top: 6px;
		}
	}

	.mat-form-field.form-field {
		.mat-form-field-wrapper {
			.mat-form-field-flex {
				padding: 0em 0.5em 0 0.5em !important;
			}
		}
	}
}

@supports (-webkit-hyphens: none) {
	.mat-form-field-type-mat-input {
		.mat-form-field-label-wrapper {
			margin-top: 6px;
		}
	}

	.mat-form-field.form-field {
		.mat-form-field-wrapper {
			.mat-form-field-flex {
				padding: 0em 0.5em 0 0.5em !important;
			}
		}
	}

	.mat-form-field.dark {
		&.mat-form-field-type-mat-select {
			.mat-form-field-infix {
				margin-top: 6px;
			}
		}
	}
}

.mat-form-field.form-field {
	.mat-form-field-wrapper {
		border-radius: 4px;
		margin: 0 !important;
		padding: 0 !important;

		.mat-form-field-flex {
			background-color: transparent;
			margin: 0 !important;
			padding: 0.4em 0.5em 0.1em 0.5em;
		}

		.mat-form-field-underline {
			display: none;
		}
	}

	.mat-form-field-subscript-wrapper {
		padding: 0 !important;
	}

	mat-hint {
		margin-top: 1.6em;
	}

	.mat-select-arrow-wrapper {
		margin-top: 16px;
	}

	&.mat-form-field-type-mat-select {
		.mat-form-field-infix {
			padding-top: 0 !important;
		}

		.mat-form-field-flex {
			padding: 0.55em 0.75em 0em 0.75em !important;
		}
	}
}

mat-form-field.dark {
	.mat-form-field-flex {
		background-color: rgb(240, 240, 240);
	}

	.mat-input-element,
	.mat-select-value *,
	.mat-select-arrow-wrapper .mat-select-arrow,
	.mat-form-field-invalid .mat-input-element {
		caret-color: black !important;
		color: var(--base-dark);
	}

	&.hide-label {
		.mat-form-field-flex {
			padding-top: 4px;
			padding-bottom: 10px;
		}

		.mat-select-arrow-wrapper {
			margin-top: 16px;
		}
	}

	input::placeholder {
		color: #c7c8ca;
		font-weight: 200;
	}

	// #1361
	&.mat-form-field-invalid {
		color: var(--color-error);

		.mat-form-field-flex {
			border: 2px solid var(--color-error);
		}

		.mat-form-field-appearance-fill .mat-form-field-ripple {
			height: 1px;
		}
	}
}

.mat-form-field.dark-color {
	.mat-form-field-wrapper {
		background-color: #1e1e1e;
		border: 1px solid #49494a;

		.mat-form-field-flex {
			background-color: transparent;

			.mat-select-placeholder {
				color: var(--color-gray);
			}
		}
	}

	&.no-background {
		.mat-form-field-wrapper {
			background-color: transparent;
		}
	}

	&.mat-focused .mat-form-field-wrapper {
		background-color: #292929;
		border-color: var(--color-primary);
	}

	.mat-select-arrow-wrapper {
		.mat-select-arrow {
			color: var(--text-color);
		}
	}
}

.mat-form-field.transparent {
	.mat-form-field-wrapper {
		background-color: transparent;
		border: 1px solid #49494a;

		.mat-form-field-flex {
			background-color: transparent;

			.mat-select-placeholder {
				color: var(--color-gray);
			}
		}
	}

	&.mat-focused .mat-form-field-wrapper {
		background-color: transparent;
		border-color: #ffffff;
	}

	.mat-select-arrow-wrapper {
		.mat-select-arrow {
			color: var(--text-color);
		}
	}
}

.error > .mat-form-field,
.mat-form-field.mat-form-field-invalid {
	&.dark-color {
		.mat-form-field-wrapper {
			border: 1px solid var(--color-error);

			&:hover {
				border: 1px solid var(--color-error);
			}
		}

		&.mat-focused .mat-form-field-wrapper {
			border-color: var(--color-error);
		}
	}
}

.mat-menu-panel {
	min-height: unset !important;
}

.mat-menu-panel.dark-color {
	background: #1e1e1e;
}

.mat-menu-panel.tiny {
	button,
	.mat-menu-item {
		line-height: 25px;
		height: 25px;
		font-size: 0.7rem;

		.mat-icon {
			font-size: 1rem;
			height: 16px;
			width: 16px;
		}
	}
}

.mat-menu-panel.no-min-height {
	min-height: 41px;
}

.mat-menu-panel.open {
	opacity: 1 !important;
	transform: scale(1) !important;
}

/*tab*/

.mat-tab-group.mat-primary .mat-ink-bar {
	background-color: #ffffff;
	height: 1px;
}

.mat-tab-header {
	border-bottom: none;

	* {
		color: var(--text-color);
		opacity: 1;
	}

	.mat-tab-label.mat-tab-label-active {
		opacity: 1;
	}
}

.mat-slide-toggle.mat-checked .mat-slide-toggle-bar,
.mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-bar {
	background-color: var(--color-primary-shade);
}

.mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-thumb {
	background-color: var(--color-primary);
}

/* tooltip */

.mat-tooltip.black {
	font-family: var(--font-family);
	font-size: 12px;

	background-color: #000000;
	border-radius: 0;
	margin: 8px;
	padding: 8px;
}

.mat-tooltip-panel-above,
.mat-tooltip-panel-below,
.mat-tooltip-panel-left,
.mat-tooltip-panel-right {
	.mat-tooltip.arrow {
		overflow: visible;

		&::before {
			content: '';
			display: block;
			position: absolute;
			border-style: solid;
			border-color: black transparent transparent transparent;
			border-width: 8px;
		}
	}
}

.mat-tooltip-panel-above .mat-tooltip.arrow::before {
	top: 100%;
	left: calc(50% - 8px);
}

.mat-tooltip-panel-below .mat-tooltip.arrow::before {
	bottom: 100%;
	left: calc(50% - 8px);
	transform: rotate(180deg);
}

.mat-tooltip-panel-left .mat-tooltip.arrow::before {
	bottom: calc(50% - 8px);
	left: 100%;
	transform: rotate(270deg);
}

.mat-tooltip-panel-right .mat-tooltip.arrow::before {
	bottom: calc(50% - 8px);
	right: 100%;
	transform: rotate(90deg);
}

//spinner
.mat-progress-spinner circle,
.mat-spinner circle {
	stroke: var(--color-primary);
}

// Progress Bar
.mat-progress-bar {
	&.white {
		.mat-progress-bar-background {
			fill: #49494A;
		}

		.mat-progress-bar-buffer {
			background-color: #49494A;
		}

		.mat-progress-bar-fill {
			&::after {
				background-color: #FFFFFF;
			}
		}
	}
}

.gtb-form {
	width: 100%;

	.mat-form-group {
		width: 100%;
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		text-align: left;
		gap: 4px;

		.form-field-title {
			font-size: 12px;
			line-height: 18px;
			color: black;
		}

		.mat-form-field.hasError {
			border: 1px solid var(--color-error);
		}

		.mat-form-field.gtb-form-field.light {
			width: 100%;

			.mat-form-field-wrapper {
				height: 48px;
				max-height: 48px;
				border-radius: 4px;
				background-color: #fafafa;
				padding: 0;
				color: black;
			}

			input {
				color: black;
			}

			input::placeholder {
				color: var(--color-dark-placeholder);
			}

			.mat-form-field-flex {
				padding: 0;
			}

			.mat-form-field-infix {
				padding: 0 16px;
				margin: 0;
				line-height: 24px;
			}

			&.mat-form-field-invalid {
				border-radius: 4px;
				border: 1px solid red;
			}

			&.no-underline {
				.mat-form-field-underline {
					display: none;
				}

				.mat-form-field-ripple {
					display: none;
				}
			}
		}
	}

	.gtb-grid {
		display: grid;
		grid-template-columns: 2fr minmax(0, 2fr) 1fr;
		gap: 15px;
	}

	.gtb-flex {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		gap: 15px;
		max-width: 35%;
		margin-bottom: 12px;
	}

	.mat-select {
		height: 100%;
		background-color: #fafafa;

		&.hasError {
			border: 1px solid var(--color-error);
		}

		.mat-select-trigger {
			height: 100%;
			justify-content: space-around;
		}

		.mat-select-arrow {
			color: var(--color-dark-placeholder);
		}

		.mat-select-min-line {
			color: black;
		}

		.mat-select-placeholder {
			color: #c7c8ca;
			font-weight: 200;
		}
	}

	.form-error-container {
		display: flex;
		font-size: 12px;

		> span,
		.mat-icon {
			color: var(--color-error);
		}
	}

	.gtb-form-error {
		.mat-checkbox-inner-container {
			border: 1px solid var(--color-error);
		}

		.mat-checkbox-label {
			* {
				color: var(--color-error) !important;
			}
		}
	}
}

/* Quill Overrides */
.ql-toolbar {
	background-color: var(--color-step-200);
}

// ngx-colors picker
.dark-color ngx-colors-panel .opened {
	background-color: var(--div-background);

	.nav-wrapper button {
		color: var(--color-dark-placeholder);
	}

	.manual-input-wrapper {
		p {
			line-height: 36px !important;
			font-size: 14px !important;
			color: var(--color-dark-placeholder) !important;
		}

		.g-input {
			border: 1px solid var(--border-gray) !important;
			height: 32px !important;

			input {
				color: var(--color-dark-placeholder) !important;
				background: var(--div-background);
			}
		}

	}
}
