.flex-button-group {
	// display: grid;
	// grid-template-columns: repeat(3, 1fr);
	// grid-column-gap: 10px;
	// grid-row-gap: 10px;
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	align-items: center;

	&.justify-start {
		justify-content: flex-start;
		text-align: left;
	}

	.left {
		flex: 1;
		display: flex;
		align-items: center;
	}

	.right {
		margin-left: auto;
		display: flex;
		align-items: center;
	}

	.form-item {
		position: relative;
		display: flex;
		align-items: flex-start;
		cursor: pointer;
		max-width: 28%;
		margin-right: 1%;
		margin-bottom: 10px;
		padding: 15px 45px;
		overflow: hidden;

		&:last-child {
			margin-right: 0;
		}

		.img-container {
			width: 45%;
			max-width: 70px;
			max-height: 70px;
			display: inline-block;
			margin-right: 15px;

			img {
				height: 100%;
				width: 100%;
				object-fit: cover;
			}
		}

		.meta {
			flex: 1;
			display: flex;
			align-content: center;
			justify-content: center;
			flex-direction: column;
			width: 65%;
			pointer-events: none;
			user-select: none;

			h3 {
				font-size: 1rem;
				line-height: 1rem;
				font-weight: bold;
				margin: 0 0 7px 0;
				color: var(--text-color);
				opacity: 0.8;
			}

			p {
				font-size: 0.85rem;
				line-height: 0.85rem;
				margin: 0;
			}

			.format {
				margin-top: 10px;
				font-size: 0.5rem;
			}
		}
	}

	&.col-4 {
		.form-item {
			width: 24%;
			margin-right: 1%;
		}
	}
}
