.p-0 {
	padding: 0 !important;
}
.p-1 {
	padding: 4px !important;
}
.p-2 {
	padding: 8px !important;
}
.p-3 {
	padding: 12px !important;
}
.p-4 {
	padding: 16px !important;
}
.p-5 {
	padding: 24px !important;
}
.p-6 {
	padding: 36px !important;
}
.p-7 {
	padding: 48px !important;
}
.p-8 {
	padding: 60px !important;
}
.p-9 {
	padding: 96px !important;
}
.pt-0 {
	padding-top: 0 !important;
}
.pt-1 {
	padding-top: 4px !important;
}
.pt-2 {
	padding-top: 8px !important;
}
.pt-3 {
	padding-top: 12px !important;
}
.pt-4 {
	padding-top: 16px !important;
}
.pt-5 {
	padding-top: 24px !important;
}
.pt-6 {
	padding-top: 36px !important;
}
.pt-7 {
	padding-top: 48px !important;
}
.pt-8 {
	padding-top: 60px !important;
}
.pt-9 {
	padding-top: 96px !important;
}
.pr-0 {
	padding-right: 0 !important;
}
.pr-1 {
	padding-right: 4px !important;
}
.pr-2 {
	padding-right: 8px !important;
}
.pr-3 {
	padding-right: 12px !important;
}
.pr-4 {
	padding-right: 16px !important;
}
.pr-5 {
	padding-right: 24px !important;
}
.pr-6 {
	padding-right: 36px !important;
}
.pr-7 {
	padding-right: 48px !important;
}
.pr-8 {
	padding-right: 60px !important;
}
.pr-9 {
	padding-right: 96px !important;
}
.pb-0 {
	padding-bottom: 0 !important;
}
.pb-1 {
	padding-bottom: 4px !important;
}
.pb-2 {
	padding-bottom: 8px !important;
}
.pb-3 {
	padding-bottom: 12px !important;
}
.pb-4 {
	padding-bottom: 16px !important;
}
.pb-5 {
	padding-bottom: 24px !important;
}
.pb-6 {
	padding-bottom: 36px !important;
}
.pb-7 {
	padding-bottom: 48px !important;
}
.pb-8 {
	padding-bottom: 60px !important;
}
.pb-9 {
	padding-bottom: 96px !important;
}
.pl-0 {
	padding-left: 0 !important;
}
.pl-1 {
	padding-left: 4px !important;
}
.pl-2 {
	padding-left: 8px !important;
}
.pl-3 {
	padding-left: 12px !important;
}
.pl-4 {
	padding-left: 16px !important;
}
.pl-5 {
	padding-left: 24px !important;
}
.pl-6 {
	padding-left: 36px !important;
}
.pl-7 {
	padding-left: 48px !important;
}
.pl-8 {
	padding-left: 60px !important;
}
.pl-9 {
	padding-left: 96px !important;
}
.m-0 {
	margin: 0 !important;
}
.m-1 {
	margin: 4px !important;
}
.m-2 {
	margin: 8px !important;
}
.m-3 {
	margin: 12px !important;
}
.m-4 {
	margin: 16px !important;
}
.m-5 {
	margin: 24px !important;
}
.m-6 {
	margin: 36px !important;
}
.m-7 {
	margin: 48px !important;
}
.m-8 {
	margin: 60px !important;
}
.m-9 {
	margin: 96px !important;
}
.mt-0 {
	margin-top: 0 !important;
}
.mt-1 {
	margin-top: 4px !important;
}
.mt-2 {
	margin-top: 8px !important;
}
.mt-3 {
	margin-top: 12px !important;
}
.mt-4 {
	margin-top: 16px !important;
}
.mt-5 {
	margin-top: 24px !important;
}
.mt-6 {
	margin-top: 36px !important;
}
.mt-7 {
	margin-top: 48px !important;
}
.mt-8 {
	margin-top: 60px !important;
}
.mt-9 {
	margin-top: 96px !important;
}
.mr-0 {
	margin-right: 0 !important;
}
.mr-1 {
	margin-right: 4px !important;
}
.mr-2 {
	margin-right: 8px !important;
}
.mr-3 {
	margin-right: 12px !important;
}
.mr-4 {
	margin-right: 16px !important;
}
.mr-5 {
	margin-right: 24px !important;
}
.mr-6 {
	margin-right: 36px !important;
}
.mr-7 {
	margin-right: 48px !important;
}
.mr-8 {
	margin-right: 60px !important;
}
.mr-9 {
	margin-right: 96px !important;
}
.mb-0 {
	margin-bottom: 0 !important;
}
.mb-1 {
	margin-bottom: 4px !important;
}
.mb-2 {
	margin-bottom: 8px !important;
}
.mb-3 {
	margin-bottom: 12px !important;
}
.mb-4 {
	margin-bottom: 16px !important;
}
.mb-5 {
	margin-bottom: 24px !important;
}
.mb-6 {
	margin-bottom: 36px !important;
}
.mb-7 {
	margin-bottom: 48px !important;
}
.mb-8 {
	margin-bottom: 60px !important;
}
.mb-9 {
	margin-bottom: 96px !important;
}
.ml-0 {
	margin-left: 0 !important;
}
.ml-1 {
	margin-left: 4px !important;
}
.ml-2 {
	margin-left: 8px !important;
}
.ml-3 {
	margin-left: 12px !important;
}
.ml-4 {
	margin-left: 16px !important;
}
.ml-5 {
	margin-left: 24px !important;
}
.ml-6 {
	margin-left: 36px !important;
}
.ml-7 {
	margin-left: 48px !important;
}
.ml-8 {
	margin-left: 60px !important;
}
.ml-9 {
	margin-left: 96px !important;
}

.w-100 {
	width: 100%!important;
}
.h-100 {
	height: 100%!important;
}

.hidden {
	display: none !important;
}

.block {
	display: block;
}

.text-center {
	text-align: center;
}

.text-white {
	color: var(--text-color);
}

.text-primary {
	color: var(--color-primary);
}

.text-success {
	color: var(--color-success);
}

.text-warning {
	color: var(--color-warning);
}

.text-danger {
	color: var(--color-danger);
}

.text-error {
	color: var(--color-error)!important;

	 * {
		color: var(--color-error)!important;
	 }
}

.text-link {
	cursor: pointer;

	&:hover {
		text-decoration: underline;
	}
}

.text-ellipsis{
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.text-balance {
	text-wrap: balance;
}

.text-pretty {
	text-wrap: pretty;
}

.overflow-hidden {
	overflow: hidden !important;
}

.uppercase {
	text-transform: uppercase !important;
}

.no-transform {
	text-transform: none;
}

.margin-left-auto {
	margin-left: auto !important;
}

.flex {
	display: flex;

	.right {
		margin-left: auto;
	}

	&.align-center {
		align-items: center;
	}

	&.justify-space-between {
		justify-content: space-between;
	}

	&.justify-end {
		justify-content: end;
	}

	&.wrap {
		flex-wrap: wrap;
	}
}

.align-self-end {
	align-self: end;
}

.align-self-stretch {
	align-self: stretch;
}

.flex-column {
	flex-direction: column;
}

.flex-row {
	flex-direction: row;
}

.flex-centered {
	display: flex;
	align-items: center;
	justify-content: center;
}
.flex-grow {
	flex-grow: 1;
}

.fl-1 {
	flex: 1;
}

.fl-2 {
	flex: 2;
}

.fl-3 {
	flex: 3;
}

.bolded {
	font-weight: 700;
}

.cursor-pointer, .pointer{
	cursor: pointer;
}

.full-width {
	width: 100%;
}

.no-pointer-events {
	pointer-events: none;
}

.grab {cursor: grab;}
.grabbing {cursor: grabbing;}

.text-code {
	padding: 2px 5px;
	background: var(--color-step-800);
	border-radius: 2px;
}
.grid {
	width: 100%;
	.row {
		display: flex;
		width: 100%;

		.column {
			flex-grow: 1;
			flex-shrink: 1;
			flex-basis: 0;
		}

		.col-1 {
			max-width: calc(100% / 12);
		}

		.col-2 {
			max-width: calc(100% / 12 * 2);
		}

		.col-3 {
			max-width: calc(100% / 12 * 3);
		}

		.col-4 {
			max-width: calc(100% / 12 * 4);
		}

		.col-5 {
			max-width: calc(100% / 12 * 5);
		}

		.col-6 {
			max-width: calc(100% / 2);
		}

		.col-7 {
			max-width: calc(100% / 12 * 7);
		}

		.col-8 {
			max-width: calc(100% / 12 * 8);
		}

		.col-9 {
			max-width: calc(100% / 12 * 9);
		}

		.col-10 {
			max-width: calc(100% / 12 * 10);
		}

		.col-11 {
			max-width: calc(100% / 12 * 11);
		}

		.col-12 {
			max-width: calc(100%);
		}
	}
}

@media screen and (max-width: 768px) {
	.column {
		min-width: 100%;
		width: 100%;
	}
}

.text-link {
	text-decoration: underline;
	cursor: pointer;
}

.fw-700 {
	font-weight: 700;
}


.grid {
	width: 100%;
	.row {
		display: flex;
		width: 100%;

		.column {
			flex-grow: 1;
			flex-shrink: 1;
			flex-basis: 0;
		}

		.col-1 {
			max-width: calc(100% / 12);
		}

		.col-2 {
			max-width: calc(100% / 12 * 2);
		}

		.col-3 {
			max-width: calc(100% / 12 * 3);
		}

		.col-4 {
			max-width: calc(100% / 12 * 4);
		}

		.col-5 {
			max-width: calc(100% / 12 * 5);
		}

		.col-6 {
			max-width: calc(100% / 2);
		}

		.col-7 {
			max-width: calc(100% / 12 * 7);
		}

		.col-8 {
			max-width: calc(100% / 12 * 8);
		}

		.col-9 {
			max-width: calc(100% / 12 * 9);
		}

		.col-10 {
			max-width: calc(100% / 12 * 10);
		}

		.col-11 {
			max-width: calc(100% / 12 * 11);
		}

		.col-12 {
			max-width: calc(100%);
		}
	}
}

@media screen and (max-width: 768px) {
	.column :not(.video-column, .video-details-column) {
		min-width: 100%;
		width: 100%;
	}
}

.scrollbar-thin {
	&::-webkit-scrollbar {
		width: 5px;
	}

	&::-webkit-scrollbar-thumb {
		background: #666;
		border-radius: 10px;
	}

	&::-webkit-scrollbar-track {
		background: #333;
		border-radius: 10px;
	}
}


.spin {
	animation: spin 1s linear infinite;
	transform-origin: center;
}

@keyframes spin {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}
