table {
	width: 100%;

	&.basic {
		tr {
			border-top: 1px solid var(--color-step-250);
		}

		th, td {
			text-align: left;
			padding: 10px 0;
		}

		th {
			font-size: 0.7rem;
			color: var(--color-step-500);
			font-weight: 400;
		}

		td {
			font-size: 0.9rem;

			&.action {
				text-align: right;
			}
		}

		&.small-content {
			td {
				font-size: 0.8rem;
			}
		}
	}

	&.border-bottom {
		border-bottom: 1px solid black;
	}

	&.persistent-actions {
		tbody td.mat-cell.mat-column-actions {
			opacity: 1;
		}
	}

	.mat-column-actions {
		 min-width: 100px;
		 text-align: right;

		button {
			display: inline-flex;
			margin-left: 4px;
			border: none;
			background: transparent;
			font-size: 1rem;
			width: 32px;
			padding: 7px 3px;
			align-items: center;
			justify-content: center;

			&:not(.loading) {
				cursor: pointer;

				&:hover {
					background: var(--color-primary);
				}

				&.danger {
					&:hover {
						background: var(--color-danger);
					}
				}
			}
		}
	}
}

.collection-table-container {
	.mat-table, .mat-paginator {
		background: #1E1E1E;
		border: 1px solid #2B2B2B;
		width: 100%;
		//width: max-content;
	}

	.mat-paginator-container{
		padding-left: 24px;
		padding-right: 24px;
	}

	.mat-paginator .mat-button-wrapper {
		display: inline;
	}


	th.mat-header-cell{
		padding: 10px 0;
		border-bottom-color:  #FFFFFF;
		color: var(--text-color) !important;
		font-weight: 700;
		font-size: 0.9rem;

	}
	td.mat-cell{
		padding: 10px 0;
		border-bottom-color:  #49494A;
		font-size: 0.8rem;

	}

}
