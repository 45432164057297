@use '@angular/material' as mat;
@include mat.core();

$md-ford: (
	50: #e0e7ef,
	100: #b3c2d7,
	200: #809abc,
	300: #4d71a1,
	400: #26528c,
	500: #003478,
	600: #002f70,
	700: #002765,
	800: #00215b,
	900: #001548,
	A100: #7c93ff,
	A200: #496aff,
	A400: #1640ff,
	A700: #002dfc,
	contrast: (
		50: #000000,
		100: #000000,
		200: #000000,
		300: #ffffff,
		400: #ffffff,
		500: #ffffff,
		600: #ffffff,
		700: #ffffff,
		800: #ffffff,
		900: #ffffff,
		A100: #000000,
		A200: #ffffff,
		A400: #ffffff,
		A700: #ffffff
	)
);

$md-ford-lime: (
	50: #e7efe4,
	100: #c3d6bb,
	200: #9cbb8e,
	300: #749f61,
	400: #568b3f,
	500: #38761d,
	600: #326e1a,
	700: #2b6315,
	800: #245911,
	900: #17460a,
	A100: #94ff7d,
	A200: #6aff4a,
	A400: #40ff17,
	A700: #2dfc00,
	contrast: (
		50: #000000,
		100: #000000,
		200: #000000,
		300: #000000,
		400: #000000,
		500: #000000,
		600: #000000,
		700: #000000,
		800: #000000,
		900: #000000,
		A100: #000000,
		A200: #000000,
		A400: #000000,
		A700: #000000
	)
);
/* ======== Angular material custom themes ======== */
$my-custom-primary: mat.define-palette($md-ford);
$my-custom-accent: mat.define-palette($md-ford-lime);
$my-custom-warn: mat.define-palette(mat.$lime-palette);
$my-custom-theme: mat.define-dark-theme((
	color: (
		primary: $my-custom-primary,
		accent: $my-custom-accent,
	),
));
@include mat.core-theme($my-custom-theme);
@include mat.all-component-themes($my-custom-theme);

@import '~quill/dist/quill.snow.css';

@import './theme/variables.scss';
@import './theme/utils.scss';
@import './theme/typography.scss';
@import './theme/overrides.scss';
@import './theme/main.scss';
@import './theme/tooltips.scss';
@import './theme/button-groups.scss';
@import './theme/table.scss';
@import './theme/widget.scss';
@import './theme/mat-button-toggle-group.scss';
