@import '~tippy.js/dist/tippy.css';
@import '~tippy.js/themes/light.css';
@import '~tippy.js/animations/scale.css';



.tippy-content {
	position: relative;
}

.tippy-close {
	position: absolute;
	width: 24px;
	height: 24px;
	top: 9px;
	right: 9px;
	fill: rgb(158, 160, 165);
	cursor: pointer;
	z-index: 1;
}

.tippy-box {
	border-radius: 4px;
	font-size: 0.8rem;

	.tippy-content {
		padding: 4px 6px;
	}
}

.tippy-box[data-theme~='light'] {
	font-size: 1rem;
	word-break: break-word;
	border-radius: 0;
	background-color: var(--color-step-800);
	box-shadow: 0 2px 14px 0 rgba(0, 0, 0, 0.2);
	color: rgb(79, 80, 83);

	.tippy-content {
		padding: 2px 4px;
	}
}

.tippy-box[data-theme~='skinny'] {
	font-size: 1rem;
	word-break: break-word;
	border-radius: 0;
	background-color: var(--color-step-800);
	box-shadow: 0 2px 14px 0 rgba(0, 0, 0, 0.2);
	color: rgb(79, 80, 83);

	.tippy-content {
		padding: 2px 4px;
	}
}

.tippy-danger {
	.tippy-box {
		background: var(--color-danger);
		color: var(--text-color);
		font-weight: bold;
	}

	.tippy-content {
		color: var(--text-color);
	}
}

.tippy-box[data-theme~='dark'] {
	border-radius: 4px;
	background: #000;

	.tippy-content {
		padding: 10px;

		display: flex;
		flex-direction: column;
		gap: 1em;
	}

	.tippy-arrow {
		color: #000
	}
}


.video-preview-tooltip {
	.tippy-box {
		border-radius: 4px;
		background: #000;

		.tippy-content {
			padding: 4px;
			padding-bottom: 2px;
		}
	}

	img, video {
		width: 100%;
		height: 100%;
	}
}

.tippy-arrow::before {
	box-shadow: -4px 4px 14px -4px rgba(0, 0, 0, 0.2);
}

.tippy-box[data-theme~="light"][data-placement^="bottom"] > .tippy-arrow::before {
	border-bottom-color: var(--color-step-800);
}

.tippy-box[data-theme~="light"][data-placement^="top"] > .tippy-arrow::before {
	border-top-color: var(--color-step-800);
}

.tippy-box[data-theme~="light"][data-placement^="left"] > .tippy-arrow::before {
	border-left-color: var(--color-step-800);
}

.tippy-box[data-theme~="light"][data-placement^="right"] > .tippy-arrow::before {
	border-right-color: var(--color-step-800);
}

.multi-line {
	white-space: pre-line;
}
