// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

/** Ionic CSS Variables **/
:root {
	--header-height: 140px;
	--font-family: 'Ford F-1', 'Helvetica Neue', Helvetica, Arial, sans-serif;
	--font-family-heading: 'Ford F-1', 'Helvetica Neue', Helvetica, Arial, sans-serif;

	--base-color: #2b2b2b;
	--base-dark: #141414;
	--base-darker: #0a0a0a;

	--base-gray-40: #b9b9b9;
	--base-gray-60: #d5d5d5;

	--white: #ffffff;
	--black: #000000;
	--text-color: #fff;

	--background-color: #171717;

	--div-background: #1e1e1e;

	--color-step-25: #f8f8f8;
	--color-step-50: #f2f2f2;
	--color-step-100: #e6e6e6;
	--color-step-150: #d9d9d9;
	--color-step-200: #cccccc;
	--color-step-250: #bfbfbf;
	--color-step-300: #b3b3b3;
	--color-step-350: #a6a6a6;
	--color-step-400: #999999;
	--color-step-450: #8c8c8c;
	--color-step-500: #808080;
	--color-step-550: #737373;
	--color-step-600: #666666;
	--color-step-650: #595959;
	--color-step-700: #4d4d4d;
	--color-step-750: #404040;
	--color-step-800: #333333;
	--color-step-850: #262626;
	--color-step-900: #191919;
	--color-step-950: #0d0d0d;

	// --color-step-25: #0d0d0d;
	// --color-step-50: #191919;
	// --color-step-100: #262626;
	// --color-step-150: #333333;
	// --color-step-200: #404040;
	// --color-step-250: #4d4d4d;
	// --color-step-300: #595959;
	// --color-step-350: #666666;
	// --color-step-400: #737373;
	// --color-step-450: #808080;
	// --color-step-500: #8c8c8c;
	// --color-step-550: #999999;
	// --color-step-600: #a6a6a6;
	// --color-step-650: #b3b3b3;
	// --color-step-700: #bfbfbf;
	// --color-step-750: #cccccc;
	// --color-step-800: #d9d9d9;
	// --color-step-850: #e6e6e6;
	// --color-step-900: #f2f2f2;
	// --color-step-950: #f8f8f8;

	/** primary **/
	--color-primary: #16817a;
	--color-primary-rgb: 56, 128, 255;
	--color-primary-contrast: #ffffff;
	--color-primary-contrast-rgb: 255, 255, 255;
	--color-primary-shade: #11645e;
	--color-primary-tint: #4c8dff;

	/** secondary **/
	--color-secondary: #3a3e45;
	--color-secondary-rgb: 61, 194, 255;
	--color-secondary-contrast: #ffffff;
	--color-secondary-contrast-rgb: 255, 255, 255;
	--color-secondary-shade: #2b2f35;
	--color-secondary-tint: #484d57;

	/** tertiary **/
	--color-tertiary: #5260ff;
	--color-tertiary-rgb: 82, 96, 255;
	--color-tertiary-contrast: #ffffff;
	--color-tertiary-contrast-rgb: 255, 255, 255;
	--color-tertiary-shade: #4854e0;
	--color-tertiary-tint: #6370ff;

	/* disabled */
	--color-primary-disabled: #333;

	/** success **/
	--color-success: #3cd5c5;
	--color-success-rgb: 45, 211, 111;
	--color-success-contrast: #ffffff;
	--color-success-contrast-rgb: 255, 255, 255;
	--color-success-shade: #28ba62;
	--color-success-tint: #42d77d;

	/** warning **/
	--color-warning: #fba12f;
	--color-warning-rgb: 255, 196, 9;
	--color-warning-contrast: #000000;
	--color-warning-contrast-rgb: 0, 0, 0;
	--color-warning-shade: #e0ac08;
	--color-warning-tint: #ffca22;

	/** danger **/
	--color-danger: #eb445a;
	--color-danger-rgb: 235, 68, 90;
	--color-danger-contrast: #ffffff;
	--color-danger-contrast-rgb: 255, 255, 255;
	--color-danger-shade: #cf3c4f;
	--color-danger-tint: #ed576b;

	/** error **/
	--color-error: #f9313d;
	--color-error-rgb: 249, 49, 61;

	/** dark **/
	--color-dark: #222428;
	--color-dark-rgb: 34, 36, 40;
	--color-dark-contrast: #ffffff;
	--color-dark-contrast-rgb: 255, 255, 255;
	--color-dark-shade: #1e2023;
	--color-dark-tint: #383a3e;
	--color-dark-placeholder: #c7c8ca;

	/** medium **/
	--color-medium: #92949c;
	--color-medium-rgb: 146, 148, 156;
	--color-medium-contrast: #ffffff;
	--color-medium-contrast-rgb: 255, 255, 255;
	--color-medium-shade: #808289;
	--color-medium-tint: #9d9fa6;

	/** light **/
	--color-light: #d5d5d5;
	--color-light-rgb: 244, 245, 248;
	--color-light-contrast: #000000;
	--color-light-contrast-rgb: 0, 0, 0;
	--color-light-shade: #d7d8da;
	--color-light-tint: #f5f6f9;

	/** gray **/
	--border-gray: #545454;
	--color-gray: #c7c8ca;
	--color-gray-hover: #e2e2e3;
	--color-gray-rgb: 199, 200, 202;
}
