@font-face {
	font-family: 'Mazda-Regular';
	src: url('/assets/font/mazda-type-regular.woff2') format('woff2'), url('/assets/font/mazda-type-regular.woff') format('woff');
	font-style: normal;
	font-weight: normal;
	text-rendering: optimizeLegibility;
}

@font-face {
	font-family: 'Mazda-Bold';
	src: url('/assets/font/mazda-type-bold.woff2') format('woff2'), url('/assets/font/mazda-type-bold.woff') format('woff');
	font-style: normal;
	font-weight: normal;
	text-rendering: optimizeLegibility;
}

@font-face {
	font-family: 'Ford F-1';
	src: url('/assets/font/FordF-1-Light.woff2') format('woff2'),
		 url('/assets/font/FordF-1-Light.woff2') format('woff');
	font-weight: 300;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Ford F-1';
	src: url('/assets/font/FordF-1-Regular.woff2') format('woff2'),
		 url('/assets/font/FordF-1-Regular.woff2') format('woff');
	font-weight: 400;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Ford F-1';
	src: url('/assets/font/FordF-1-Medium.woff2') format('woff'),
		 url('/assets/font/FordF-1-Medium.woff2') format('woff2');
	font-weight: 500;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Ford F-1';
	src: url('/assets/font/FordF-1-Semibold.woff2') format('woff'),
		 url('/assets/font/FordF-1-Semibold.woff2') format('woff2');
	font-weight: 600;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Ford F-1';
	src: url('/assets/font/FordF-1-Bold.woff2') format('woff'),
	 	 url('/assets/font/FordF-1-Bold.woff2') format('woff2');
	font-weight: 700;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Coca Cola';
	src:
		url('https://cceencoderstackhk-videoencoder5499e89e-bzauc46gbptw.s3.ap-east-1.amazonaws.com/assets/fonts/tccc-unitytext-regular.woff2')
		format('woff2');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

// @font-face {
// 	font-family: 'Mazda-Regular';
// 	src: url('/assets/font/InfinitiBrandL1-Light.woff2') format('woff2');
// 	font-style: normal;
// 	font-weight: normal;
// 	text-rendering: optimizeLegibility;
// }

// @font-face {
// 	font-family: 'Mazda-Bold';
// 	src: url('/assets/font/InfinitiBrandL1-Regular.woff2') format('woff2');
// 	font-style: normal;
// 	font-weight: normal;
// 	text-rendering: optimizeLegibility;
// }

* {
	// font-family: Helvetica, sans-serif;
	font-family: var(--font-family);
	color: var(--text-color);
}

a {
	color: var(--text-color);
}

h1,
h2,
h3,
h4,
h5,
h6,
.mat-h1,
.mat-subheading-1,
.mat-typography .mat-h1,
.mat-typography .mat-subheading-1,
.mat-typography h1,
.mat-h2,
.mat-subheading-2,
.mat-typography .mat-h2,
.mat-typography .mat-subheading-2,
.mat-typography h2,
.mat-typography h2 .mat-h3,
.mat-subheading-3,
.mat-typography .mat-h3,
.mat-typography .mat-subheading-3,
.mat-typography h3,
.mat-h4,
.mat-subheading-4,
.mat-typography .mat-h4,
.mat-typography .mat-subheading-4,
.mat-typography h4,
.mat-h5,
.mat-subheading-5,
.mat-typography .mat-h5,
.mat-typography .mat-subheading-5,
.mat-typography h5,
.mat-h6,
.mat-subheading-6,
.mat-typography .mat-h6,
.mat-typography .mat-subheading-6,
.mat-typography h6 {
	font-family: var(--font-family-heading);
	font-weight: bold;
}

// strong,
// .bold {
// 	font-family: Helvetica, sans-serif;
// }
