/* You can add global styles to this file, and also import other style files */

html,
body {
	height: 100%;
}
body {
	margin: 0;
	color: var(--text-color);
}
