.mat-button-toggle-group.secondary {
  flex-grow: 0;
  flex-shrink: 0;

  .mat-button-toggle {
    display: flex;
    border: 1px solid transparent;
    background: transparent;
  }

  .mat-button-toggle-checked {
    background-color: #292929;
    border-color: #FFFFFF;
    border-radius: 3px;
  }

  .mat-button-toggle-label-content {
    display: flex;
    padding: 0 20px;
    justify-items: center;
    font-weight: 700;
  }
}
